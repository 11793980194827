import { getForum } from 'API/getForum'
import { addNotification } from 'API/notifications'
import { myCourses } from 'API/course'
import { Form } from 'components/Form/Form'
import { PageProps, navigate } from 'gatsby'
import { useEditableHook } from 'hooks/useEditableHook'
import React, { useState, useEffect } from 'react'
import { RetType, Course } from '../../../../../pages/user/courses/CourseComponent'
import AddClassesTable from '../../../../../components/admin/AddClassesTable'
import { CustomModal } from '../../../../../components/common/useModal'
import { PromiseType } from 'utility-types'
import { addFileToCDN, addFileToCDNForPublic } from './addFileToCDN'

const Notification: React.FC<PageProps> = ({ params: { id: course }, location }) => {
	let ref = React.useRef<HTMLButtonElement | null>(null)
	const { edit, elem_id } = useEditableHook({ location })
	const [editable, setEditable] = React.useState<any>({})
	const [success, setSuccess] = React.useState<any>([])
	const [failed, setfailed] = React.useState<any>([])
	const [loading, setLoading] = useState(false)
	const [isNoNotifictaion, setIsNoNotification] = useState<string | null>(null)
	const columns = [
		{
			text: 'Subject',
			dataField: 'title',
			sort: true,
		},
		{
			text: 'Message',
			dataField: 'description',
			sort: true,
		},
		{
			text: 'Receiver',
			dataField: 'sent_to.username',
			sort: true,
		},
		{
			type: 'image',
			name: 'file',
			label: 'Add file',
			optional: edit === 'true',
		},
	]

	React.useEffect(() => {
		if (edit === 'true' && elem_id !== '') {
			const run = async () => {
				const asset = await getForum(elem_id, 'Teacher')
				if (asset) {
					console.log('asset', asset)
					setEditable(asset)
				}
			}
			run()
		}
	}, [edit, elem_id])
	const [courses, setCourses] = useState<RetType>({
		studying: [
			// {
			// 	course: online,
			// },
		],
		teaching: [],
	})
	useEffect(() => {
		const run = async () => {
			const courses = await myCourses()
			setCourses(courses)
		}
		run()
	}, [])
	return (
		<>
			<CustomModal
				header='Add Notification'
				onClick={async () => {
					if (edit === 'true') {
						if (ref.current) {
							ref.current.click()
						}
					}
					console.log(ref)
					if (ref.current) {
						ref.current.click()
					}
				}}
			>
				<Form
					editableState={editable}
					getRef={(_ref) => {
						if (_ref) ref = _ref
					}}
					action={async ({ state }) => {
						let resp: PromiseType<ReturnType<typeof addFileToCDN>>
						// console.log(resp)
						if (state.file) {
							resp = await addFileToCDN({ file: state.file, name: state.file.name })
							// setLoading(false)
							if (resp && state.file) {
								const state_copy = Object.assign({}, state)
								delete state_copy['file']
								state.course = [course]
								console.log(resp.uid)
								const add = await addNotification({
									course: state.course,
									description: state.description,
									title: state.title,
									cdn: resp.uid,
									include_teacher: state.include_teacher,
								})
								console.log(add)
								if (add) {
									alert('Done Successfully sent to ' + add.success.length)
									// success.push(...add.success)
									// navigate('/notification')
								}
							}
						} else {
							if (!state.file) {
								const state_copy = Object.assign({}, state)
								delete state_copy['file']
								state.course = [course]
								const add = await addNotification({
									course: state.course,
									description: state.description,
									title: state.title,
									cdn: null,
									include_teacher: state.include_teacher,
								})
								if (add) {
									alert('Done Successfully sent to ' + add.success.length)
									success.push(...add.success)
									navigate('/notification')
								}
							}
						}
					}}
					text={'Add Notification'}
					fields={[
						{
							type: 'text',
							label: 'Subject',
							name: 'title',
						},
						{
							type: 'textarea',
							label: 'Description',
							name: 'description',
						},
						{
							type: 'select',
							selection: [
								{ label: 'Only Students', value: false },
								{ label: 'Everyone', value: true },
							],
							label: 'Select Group',
							name: 'include_teacher',
							default_text: 'Select Group',
						},
						{
							type: 'image',
							name: 'file',
							label: 'Add file',
							optional: true,
						},
						// {
						// 	type: 'select',
						// 	selection: [
						// 		{ label: 'Only Students', value: false },
						// 		{ label: 'Everyone', value: true },
						// 	],
						// 	label: 'Select Course',
						// 	name: 'include_teacher',
						// 	default_text: 'Select Group',
						// },
					]}
				></Form>
				{/* <AddClassesTable data={success} columns={columns} /> */}
			</CustomModal>
		</>
	)
}

export default Notification
